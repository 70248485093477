import { useEffect } from "react";
import { useUser } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { updateUserData } from "../firebase/auth";

const OAuth2 = () => {
    const { user, setUserContext } = useUser();
    const navigate = useNavigate();

    const processOAuth2Response = () => {
        try {
            const urlHash = new URLSearchParams(window.location.hash.substring(1));
            const token = urlHash.get("access_token");

            if (!user) {
                console.error("No user found in the context.");
                navigate("/login");
                return;
            }

            setUserContext({ GOOGLE_BEARER_TOKEN: token });
            updateUserData(user.uid, { GOOGLE_BEARER_TOKEN: token });

            navigate("/profile", { state: { from: "oauth2", token: token } });

        } catch (error) {
            console.error("Error processing OAuth2 response:", error);
            navigate("/login");
        }
    };

    useEffect(() => {
        if (user.uid !== null) {
            processOAuth2Response();
        }
    }
        , [user]);

    return <div>This is the auth page</div>;
}

export default OAuth2;
