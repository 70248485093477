import axios from "axios";

// Configuration
const BATCH_SIZE = 5; // Number of requests per batch
const BATCH_DELAY = 60000; // Delay between batches in milliseconds (1 minute)

const createThread = async (message, apiKey) => {
    const url = "https://api.openai.com/v1/threads";
    const res = await axios.post(
        url,
        {
            messages: [
                {
                    role: "user",
                    content: message,
                },
            ],
        },
        {
            headers: {
                Authorization: `Bearer ${apiKey}`,
                "Content-Type": "application/json",
                "OpenAI-Beta": "assistants=v2",
            },
        }
    );
    return res.data.id;
};

const createRun = async (threadId, assistantId, apiKey) => {
    const url = `https://api.openai.com/v1/threads/${threadId}/runs`;
    const res = await axios.post(
        url,
        {
            assistant_id: assistantId,
        },
        {
            headers: {
                Authorization: `Bearer ${apiKey}`,
                "Content-Type": "application/json",
                "OpenAI-Beta": "assistants=v2",
            },
        }
    );
    return res.data.id;
};

// Retrieve the run status and retry on rate limits
const retrieveRunWithRetry = async (threadId, runId, apiKey) => {
    const url = `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`;
    while (true) {
        try {
            const res = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                    "Content-Type": "application/json",
                    "OpenAI-Beta": "assistants=v2",
                },
            });

            if (res.data.status === "completed") {
                return res;
            } else if (res.data.status === "failed") {
                if (res.data.last_error && res.data.last_error.code === "rate_limit_exceeded") {
                    const retryAfter = Math.ceil(parseFloat(res.data.last_error.message.match(/try again in (\d+(\.\d+)?)/)[1]) * 1000);
                    console.warn(`Rate limit exceeded. Retrying after ${retryAfter / 1000} seconds...`);
                    await new Promise(resolve => setTimeout(resolve, retryAfter));
                } else {
                    throw new Error(`Request failed with error: ${res.data.last_error ? res.data.last_error.message : 'Unknown error'}`);
                }
            }
        } catch (error) {
            console.error('Error retrieving run:', error);
            throw error;
        }

        // If not completed or failed due to rate limits, retry after a short delay
        await new Promise(r => setTimeout(r, 2500));
    }
};

const getResponse = async (threadId, runId, apiKey) => {
    const res = await retrieveRunWithRetry(threadId, runId, apiKey);
    const messages = await listMessages(threadId, apiKey);
    return messages.data[0].content[0].text.value;
};

const listMessages = async (threadId, apiKey) => {
    const url = `https://api.openai.com/v1/threads/${threadId}/messages`;
    const res = await axios.get(url, {
        headers: {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
        },
    });
    return res.data;
};

// Batch processing function
const processBatch = async (batch, assistantId, apiKey) => {
    const results = [];
    for (const input of batch) {
        try {
            const threadId = await createThread(input, apiKey);
            const runId = await createRun(threadId, assistantId, apiKey);
            const response = await getResponse(threadId, runId, apiKey);
            results.push(response);
        } catch (error) {
            console.error("Error processing input:", error);
        }
    }
    return results;
};

// Main function to send requests in batches
export const sendPromptsInBatches = async (prompts, assistantId, apiKey) => {
    const allResults = [];
    for (let i = 0; i < prompts.length; i += BATCH_SIZE) {
        const batch = prompts.slice(i, i + BATCH_SIZE);
        const results = await processBatch(batch, assistantId, apiKey);
        allResults.push(...results);

        // Delay between batches to avoid rate limit issues
        if (i + BATCH_SIZE < prompts.length) {
            console.log(`Waiting ${BATCH_DELAY / 1000} seconds before processing the next batch...`);
            await new Promise((r) => setTimeout(r, BATCH_DELAY));
        }
    }

    return allResults;
};

export const getOpenAIAssistants = async (apiKey) => {
    const url = "https://api.openai.com/v1/assistants";

    const res = await axios.get(url, {
        headers: {
            "Authorization": `Bearer ${apiKey}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2"
        }
    });

    return res.data.data;
};
