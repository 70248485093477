const harmonizeUserData = (authUser, firestoreUser, place = "auth") => {
    let mergedUser = {};

    if (place === "auth") {
        mergedUser.email = authUser.user.email;
        mergedUser.uid = authUser.user.uid;
        mergedUser.accessToken = authUser.user.accessToken;
    } else {
        mergedUser.email = authUser.email;
        mergedUser.uid = authUser.uid;
        mergedUser.accessToken = authUser.accessToken;
    }

    mergedUser = { ...mergedUser, ...firestoreUser };

    return mergedUser;
}

export default harmonizeUserData;