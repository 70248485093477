import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Divider, IconButton, Avatar, Typography, Box, Menu, MenuItem } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import ChatBotIcon from '@mui/icons-material/Chat';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { useUser } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
    const { user, logout } = useUser();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        await logout();
        navigate('/login');
        handleMenuClose();
    };

    const handleProfile = () => {
        navigate('/profile');
        handleMenuClose();
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const menuItems = [
        { text: 'Home', icon: <HomeIcon />, onClick: () => navigate('/') },
        { text: 'Data For Seo', icon: <DataUsageIcon />, onClick: () => navigate('/data-for-seo') },
        { text: 'Content Bot', icon: <ChatBotIcon />, onClick: () => navigate('/content-bot') },
    ];

    return (
        <>
            <Box
                sx={{ width: 300, backgroundColor: 'background.default' }}
                role="presentation"
                onClick={toggleDrawer}
                onKeyDown={toggleDrawer}
            >
                <List>
                    {menuItems.map((item, index) => (
                        <ListItem button key={index} onClick={item.onClick}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItem>
                    ))}
                </List>

            </Box>
        </>
    );
};

export default Sidebar;
