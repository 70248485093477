import { useEffect } from 'react';
import { useUser } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
    const { user } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        // wait 1 second and if user.uid is null then navigate to login page
        const timeout = setTimeout(() => {
            if (!user?.uid) {
                navigate('/login');
            }
        }, 700);

        return () => clearTimeout(timeout);
    }, [user]);


    return children;
};

export default PrivateRoute;
