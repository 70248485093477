import React, { useEffect, useState } from 'react';
import {
    TextField,
    Button,
    Box,
    Typography,
    CircularProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
    useTheme,
    Link,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';

import { createFolder, checkBearerToken, createDoc, updateDoc, moveDocToFolder } from '../utils/google/gapi';
import { sendPromptsInBatches } from '../utils/openai/openaiapi';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useUser } from '../contexts/UserContext';

const Dashboard = () => {
    const [folderName, setFolderName] = useState('');
    const [inputs, setInputs] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [operations, setOperations] = useState([]);
    const [selectedAssistant, setSelectedAssistant] = useState('');

    const { user } = useUser();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        console.log(selectedAssistant);
    }, [selectedAssistant]);

    const handleProcess = () => {
        if (!folderName || !inputs || !selectedAssistant) {
            alert('Please fill in all fields');
            return;
        }

        setIsProcessing(true);
        startOperations();
    };

    const startOperations = async () => {
        console.log(inputs);

        const inputsArray = inputs.split(/,|\n/).map((input) => input.trim()).filter((input) => input.length > 0);

        const isGoogleTokenAvailable = await checkBearerToken(user.GOOGLE_BEARER_TOKEN);

        if (!isGoogleTokenAvailable) {
            setIsProcessing(false);
            alert('Google Bearer Token is not available. Please authenticate with Google first.');
            return;
        }

        setOperations((prev) => [...prev, { name: "Folder: " + folderName, status: 'running' }]);
        const folderId = await createFolder(folderName, user.GOOGLE_BEARER_TOKEN);
        setOperations((prev) =>
            prev.map((op) =>
                op.name === "Folder: " + folderName ? { name: "Folder: " + folderName, status: 'done', folder_id: folderId } : op
            )
        );

        // Process inputs in batches using sendPromptsInBatches
        try {
            // add an operation that states waiting for the repsponse
            setOperations((prev) => [...prev, { name: "Processing Inputs With Open AI. \n It will take 1 minutes to 30 minutes depending on your input lenght.", status: 'running' }]);
            const completions = await sendPromptsInBatches(inputsArray, selectedAssistant.id, user.OPEN_API_KEY);

            // if completions complete, update the operation status to done
            setOperations((prev) =>
                prev.map((op) =>
                    op.name === "Processing Inputs With Open AI. \n It will take 1 minutes to 30 minutes depending on your input lenght." ? { name: "All Inputs Processed", status: 'done' } : op
                )
            );



            // Update operations with results for each document
            for (let i = 0; i < inputsArray.length; i++) {
                const input = inputsArray[i];
                const completion = completions[i];

                setOperations((prev) => [...prev, { name: "Doc: " + input, status: 'running' }]);

                try {
                    const docId = await createDoc(`${folderName} - ${input}`, folderId, user.GOOGLE_BEARER_TOKEN);
                    await updateDoc(docId, completion, user.GOOGLE_BEARER_TOKEN);
                    const moved = await moveDocToFolder(docId, folderId, user.GOOGLE_BEARER_TOKEN);

                    if (moved) {
                        setOperations((prev) =>
                            prev.map((op) =>
                                op.name === "Doc: " + input ? { name: "Doc: " + input, status: 'done', doc_id: docId } : op
                            )
                        );
                    }
                } catch (error) {
                    console.error('Error processing input:', error);
                    setOperations((prev) =>
                        prev.map((op) =>
                            op.name === "Doc: " + input ? { ...op, status: 'error' } : op
                        )
                    );
                }
            }
        } catch (error) {
            console.error("Error during batch processing:", error);
        }

        setIsProcessing(false);
    };

    if (user?.uid === null) {
        return <>Loading...</>;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                flexGrow: 1,
                padding: 4,
            }}
        >
            <Box sx={{ flex: isMobile ? 0 : 1, marginRight: isMobile ? 0 : 4, marginBottom: isMobile ? 4 : 0, width: isMobile ? '100%' : '70%' }}>
                <Typography variant="h5" gutterBottom>
                    Process Files
                </Typography>
                <TextField
                    label="Folder Name"
                    value={folderName}
                    onChange={(e) => setFolderName(e.target.value)}
                    disabled={isProcessing}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Inputs"
                    value={inputs}
                    onChange={(e) => setInputs(e.target.value)}
                    disabled={isProcessing}
                    fullWidth
                    placeholder='Separate inputs with "," or new line'
                    margin="normal"
                    multiline
                    rows={4}
                />
                <FormControl fullWidth margin="normal" disabled={isProcessing}>
                    <InputLabel id="select-assistant-label">Select Assistant</InputLabel>
                    <Select
                        labelId="select-assistant-label"
                        value={selectedAssistant?.name}
                        onChange={(e) => setSelectedAssistant(user?.OPEN_API_ASSISTANTS.find((assistant) => assistant.id === e.target.value))}
                        label="Select Assistant"
                    >
                        {
                            user?.OPEN_API_ASSISTANTS?.map((assistant) => (
                                <MenuItem key={assistant.id} value={assistant.id}>
                                    {assistant.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleProcess}
                    disabled={isProcessing}
                    sx={{ marginTop: 2 }}
                    fullWidth={isMobile}
                >
                    Process
                </Button>
            </Box>
            <Box sx={{ width: isMobile ? '100%' : "30%", borderLeft: isMobile ? 'none' : '1px solid #ccc', paddingLeft: isMobile ? 0 : 2 }}>
                <Typography variant="h6" gutterBottom>
                    Operations ({selectedAssistant?.name})
                </Typography>
                <List>
                    {operations.map((operation, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                {operation.status === 'running' ? (
                                    <CircularProgress size={24} />
                                ) : operation.status === 'done' ? (
                                    <CheckCircleIcon color="success" />
                                ) : (
                                    <CancelIcon color="error" />
                                )}
                            </ListItemIcon>
                            <Link
                                sx={{ textDecoration: operation.status === "done" ? "underline" : "none", color: operation.status === 'done' ? 'green' : 'inherit', cursor: operation.status === 'done' ? 'pointer' : 'not-allowed' }}
                                href={operation.doc_id ? `https://docs.google.com/document/d/${operation.doc_id}` :
                                    operation.folder_id ? `https://drive.google.com/drive/folders/${operation.folder_id}` : '#'
                                } disabled={operation.status !== 'done'} target="_blank" rel="noreferrer">
                                <ListItemText primary={operation.name} />
                            </Link>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Box>
    );
}

export default Dashboard;
