import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Box, Typography } from '@mui/material';
import { handleFirebaseLogin } from '../firebase/auth';
import { useUser } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const { user, login } = useUser();

    const handleLogin = async (e) => {
        e.preventDefault();
        const userData = await handleFirebaseLogin(email, password);
        if (userData) {
            login(userData);
            navigate('/');

        } else {
            setError(true);
        }
    };

    useEffect(() => {
        if (user?.uid !== null) {
            navigate('/');
        }
    }, [user, navigate]);

    return (
        <Container maxWidth="xs">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
            >
                <Box
                    component="div"
                    sx={{
                        padding: 3,
                        borderRadius: 2,
                        boxShadow: 3,
                        border: '2px solid',
                        borderColor: 'primary.main',
                        backgroundColor: 'background.paper',
                    }}
                >
                    <Typography variant="h4" component="h1" gutterBottom>
                        Login
                    </Typography>
                    {error && <Typography color="error">
                        There was an error logging in. Please try again.
                    </Typography>}
                    <Box component="form" noValidate autoComplete="off" onSubmit={e => handleLogin(e)}>
                        <TextField
                            type='email'
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default LoginPage;
