import axios from 'axios';


export const createFolder = async (folderName, bearerToken) => {
    const trimmedToken = bearerToken.trim(); // Trim the token to remove any extra spaces

    console.log("Bearer Token:", trimmedToken); // Log the token to ensure it's correct

    // check if any folder exists with the same name
    const folderExists = await axios.get(
        `https://www.googleapis.com/drive/v3/files?q=name='${folderName}'`,
        {
            headers: {
                Authorization: `Bearer ${trimmedToken}`,
                "Content-Type": "application/json",
                "Accept": "*/*",
            }
        }
    );

    if (folderExists.data.files.length > 0) {
        console.log("Folder already exists:", folderExists.data.files[0]);
        return folderExists.data.files[0].id;
    }

    const folder = {
        name: folderName,
        mimeType: "application/vnd.google-apps.folder"
    };

    try {
        console.log("Requesting folder creation...");

        const response = await axios.post(
            "https://www.googleapis.com/drive/v3/files",
            folder,
            {
                headers: {
                    Authorization: `Bearer ${trimmedToken}`,
                    "Content-Type": "application/json",
                    "Accept": "*/*",
                }
            }
        );

        console.log("Folder created successfully:", response.data);
        return response.data.id;
    } catch (error) {
        console.error("Error creating folder:", error);
        if (error.response) {
            console.error("Response data:", error.response.data);
            console.error("Response status:", error.response.status);
            console.error("Response headers:", error.response.headers);
        }
    }
};

export const createDoc = async (docName, folderId, bearerToken) => {
    const trimmedToken = bearerToken.trim(); // Trim the token to remove any extra spaces

    console.log("Bearer Token:", trimmedToken); // Log the token to ensure it's correct

    const doc = {
        name: docName,
        mimeType: "application/vnd.google-apps.document",
        parents: [{ id: folderId }]
    };

    try {
        console.log("Requesting doc creation...");

        const response = await axios.post(
            "https://www.googleapis.com/drive/v3/files",
            doc,
            {
                headers: {
                    Authorization: `Bearer ${trimmedToken}`,
                    "Content-Type": "application/json",
                    "Accept": "*/*",
                }
            }
        );

        console.log("Doc created successfully:", response.data);
        return response.data.id;
    } catch (error) {
        console.error("Error creating doc:", error);
        if (error.response) {
            console.error("Response data:", error.response.data);
            console.error("Response status:", error.response.status);
            console.error("Response headers:", error.response.headers);
        }
    }
}

export const updateDoc = async (docId, data, bearerToken) => {
    const trimmedToken = bearerToken.trim(); // Trim the token to remove any extra spaces

    console.log("Bearer Token:", trimmedToken); // Log the token to ensure it's correct



    try {
        console.log("Requesting doc update...");

        const response = await axios.post(
            `https://docs.googleapis.com/v1/documents/${docId}:batchUpdate`,
            {
                requests: [
                    {
                        insertText: {
                            location: {
                                index: 1,
                            },
                            text: data,
                        },
                    },
                ],
            },
            {
                headers: {
                    Authorization: `Bearer ${trimmedToken}`,
                    "Content-Type": "application/json",
                    "Accept": "*/*",
                }
            }
        );

        console.log("Doc updated successfully:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error updating doc:", error);
        if (error.response) {
            console.error("Response data:", error.response.data);
            console.error("Response status:", error.response.status);
            console.error("Response headers:", error.response.headers);
        }
    }
}

export const moveDocToFolder = async (fileId, newFolderId, bearerToken) => {
    const trimmedToken = bearerToken.trim();

    console.log("Bearer Token:", trimmedToken);

    try {
        console.log(`Moving document with ID: ${fileId} to folder: ${newFolderId}`);

        // Get the current parents
        const getFileResponse = await axios.get(
            `https://www.googleapis.com/drive/v3/files/${fileId}?fields=parents`,
            {
                headers: {
                    Authorization: `Bearer ${trimmedToken}`,
                }
            }
        );

        const currentParents = getFileResponse.data.parents || [];

        // Move the document by updating its parent
        const response = await axios.patch(
            `https://www.googleapis.com/drive/v3/files/${fileId}?addParents=${newFolderId}&removeParents=${currentParents.join(',')}&fields=id,parents`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${trimmedToken}`,
                    "Content-Type": "application/json",
                }
            }
        );

        console.log("Document moved successfully:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error moving document:", error);
        if (error.response) {
            console.error("Response data:", error.response.data);
            console.error("Response status:", error.response.status);
            console.error("Response headers:", error.response.headers);
        }
        throw error;
    }
};

export const checkBearerToken = async (bearerToken, isProfile = false) => {
    const trimmedToken = bearerToken.trim(); // Trim the token to remove any extra spaces

    console.log("Bearer Token:", trimmedToken); // Log the token to ensure it's correct

    try {
        console.log("Checking token...");

        const response = await axios.get(
            "https://www.googleapis.com/oauth2/v1/tokeninfo",
            {
                headers: {
                    Authorization: `Bearer ${trimmedToken}`,
                    "Content-Type": "application/json",
                    "Accept": "*/*",
                }
            }
        );

        console.log("Token is valid:", response.data);
        return response.data;
    } catch (error) {
        if (isProfile) {
            throw error
        }
        console.error("Error checking token:", error);
        if (error.response) {
            console.error("Response data:", error.response.data);
            console.error("Response status:", error.response.status);
            console.error("Response headers:", error.response.headers);
        }

    }
}