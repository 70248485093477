import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "./firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import harmonizeUserData from "../utils/firebase/harmonizeUserData";

export const handleFirebaseLogin = async (email, password) => {
    let mergedUser = {};

    try {
        const user = await signInWithEmailAndPassword(auth, email, password);
        const firestoreUser = await getUserData(user.user.uid);

        mergedUser = harmonizeUserData(user, firestoreUser);

        console.log(mergedUser);


        return mergedUser;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getUserData = async (uid) => {
    const userRef = doc(db, "users", uid);
    const userDoc = await getDoc(userRef);

    return userDoc.data();
}

export const updateUserData = async (uid, data) => {
    const userRef = doc(db, "users", uid);
    await updateDoc(userRef, data);
}