import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Box, Avatar, Menu, MenuItem, Divider, ListItemIcon, ListItemText } from '@mui/material';
import { useUser } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';

const Navbar = () => {
    const { user, logout } = useUser();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        console.log(user);

    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        await logout();
        navigate('/login');
        handleMenuClose();
    };

    const handleProfile = () => {
        navigate('/profile');
        handleMenuClose();
    };

    return (
        <AppBar position="static"
            // no shadow
            sx={{
                boxShadow: 'none',
            }}>
            <Toolbar>
                <Typography variant="h6" sx={{ flexGrow: 1 }} >
                    AI Content Generator
                </Typography>
                {user ? (
                    <>
                        <IconButton edge="end" color="inherit" onClick={handleMenuOpen}>
                            <Avatar alt={user.email} src="/static/images/avatar/1.jpg" />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={handleProfile}>
                                <Avatar />
                                <Box ml={2}>
                                    <Typography variant="body1">{user.email}</Typography>
                                    <Typography variant="body2" color="textSecondary">Profile</Typography>
                                </Box>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={handleLogout}>
                                <ListItemIcon>
                                    <LogoutIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Logout" />
                            </MenuItem>
                        </Menu>
                    </>
                ) : (
                    <Button color="inherit" href="/login">Login</Button>
                )}
            </Toolbar>
        </AppBar >
    );
};

export default Navbar;
