import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2', // Blue color
        },
        secondary: {
            main: '#dc004e', // Pink color
        },
        background: {
            default: '#f5f5f5', // Light grey background
        },
        text: {
            primary: '#333', // Darker text
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h4: {
            fontWeight: 600,
            color: '#1976d2',
        },
    },
    shape: {
        borderRadius: 8, // Rounded corners
    },
});

export default theme;
