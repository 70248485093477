import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <Container maxWidth="md" sx={{
            textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'
            , minHeight: "100dvh",
            justifyContent: "center"
        }}>
            <Typography variant="h1" component="h1" gutterBottom>
                404
            </Typography>
            <Typography variant="h4" component="h2" gutterBottom>
                Page Not Found
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
                Sorry, the page you are looking for does not exist.
            </Typography>
            <Box mt={4}>
                <Button variant="contained" color="primary" onClick={handleGoHome}>
                    Go to Home
                </Button>
            </Box>
        </Container>
    );
};

export default NotFoundPage;
