import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useUser } from "./contexts/UserContext";
import LoginPage from "./pages/LoginPage";
import ProtectedRouter from "./ProtectedRouter";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase/firebase";
import NotFoundPage from "./pages/NotFoundPage";
import Profile from "./pages/Profile";
import Dashboard from "./pages/Dashboard";
import OAuth2 from "./pages/OAuth2";
import { ThemeProvider } from "@emotion/react";
import theme from "./theme";

const App = () => {
  const { login } = useUser();
  const [checking, setChecking] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        login(user);
      }
      setChecking(false);
    });


    return () => unsubscribe();
  }, [login]);


  return !checking && (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ProtectedRouter element={<Dashboard />} />} />
          <Route path="/profile" element={<ProtectedRouter element={<Profile />} />} />
          <Route path="/oauth2" element={<ProtectedRouter element={<OAuth2 />} />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>

    </ThemeProvider>
  );
}

export default App;