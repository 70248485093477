import { onAuthStateChanged, signOut } from 'firebase/auth';
import React, { createContext, useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { auth } from '../firebase/firebase';
import { getUserData } from '../firebase/auth';
import harmonizeUserData from '../utils/firebase/harmonizeUserData';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const nullUser = {
        uid: null,
        email: null,
        displayName: null,
        photoURL: null,
        OPEN_API_KEY: null,
        GOOGLE_CLIENT_ID: null,
        GOOGLE_BEARER_TOKEN: null,
        OPEN_API_ASSISTANTS: [],
        SELECTED_ASSISTANT: null,
    };

    const [user, setUser] = useState(nullUser);

    const login = useCallback(async (userData) => {
        if (!userData.OPEN_API_KEY) {
            const firestoreUser = await getUserData(userData.uid);
            setUser(harmonizeUserData(userData, firestoreUser, "context"));
        } else {
            setUser(user => ({ ...user, ...userData }));
        }
    }, []);

    const logout = useCallback(async () => {
        await signOut(auth);
        setUser(nullUser);
    }, []);

    const setUserContext = useCallback((userData) => {
        setUser(prevUser => ({ ...prevUser, ...userData }));
    }, []);


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
            if (authUser) {
                const firestoreUser = await getUserData(authUser.uid);
                setUser(harmonizeUserData(authUser, firestoreUser, "context"));
            } else {
                setUser(nullUser);
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const contextValue = useMemo(() => ({
        user,
        setUserContext,
        login,
        logout,
    }), [user, login, logout]);

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    return useContext(UserContext);
};
