import PrivateRoute from "./utils/PrivateRoute";
import Navbar from "./components/Navbar";
import { Container } from "@mui/material";
import Sidebar from "./components/Sidebar";

const ProtectedRouter = ({ element }) => {
    return (<PrivateRoute>
        <Container disableGutters maxWidth={false}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100dvh',
            }}
        >
            <Navbar />
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                }}
                disableGutters
                maxWidth={false}
            >
                <Sidebar />
                {element}
            </Container>
        </Container>
    </PrivateRoute>);
}

export default ProtectedRouter;